var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{on:{"mouseup":() =>
      _vm.handleMouseUp(
        _vm.$event,
        _vm.name || _vm.parentData.xtag,
        _vm.data.nodeId || _vm.parentNodeId
      ),"mousedown":_vm.handleMouseDown,"click":function($event){$event.stopPropagation();return _vm.selectTag(_vm.name || _vm.parentData.xtag, _vm.data.nodeId || _vm.parentNodeId)}}},[_c('div',[(typeof _vm.errorMessage === 'string')?_c('div',[_c('div',{staticClass:"text-center"},[_c('div',{staticClass:"d-flex justify-content-center"},[_c('div',{staticClass:"position-relative"},[_c('img',{staticClass:"error-image",attrs:{"src":require("../../../../assets/placeholder.png"),"alt":"Placeholder Image"}}),_c('div',{staticClass:"error-text"},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")])])])])]):(typeof _vm.data === `string`)?_c('textarea',{ref:"inputtext_0",staticClass:"inputtext",class:[
        { bold: _vm.parentData.xtag === 'b' || _vm.parentData.ancestors.b },
        { italic: _vm.parentData.xtag === 'i' || _vm.parentData.ancestors.i },
        { underline: _vm.parentData.xtag === 'u' || _vm.parentData.ancestors.u },
      ],attrs:{"id":"popover-target-1","rows":"1"},domProps:{"value":_vm.data},on:{"input":_vm.updateData,"mouseup":() =>
          _vm.handleMouseUp(
            _vm.$event,
            _vm.name || _vm.parentData.xtag,
            _vm.data.nodeId || _vm.parentNodeId
          ),"mousedown":_vm.handleMouseDown,"click":function($event){$event.stopPropagation();return _vm.selectTag(_vm.name || _vm.parentData.xtag, _vm.data.nodeId || _vm.parentNodeId)}}}):_c('div',[_c('div',{staticClass:"d-flex flex-row align-items-center"},[_c('div',[_c('span',{staticClass:"collapse-icon pr-2",on:{"click":function($event){$event.stopPropagation();return _vm.collapse()}}},[_vm._v(" "+_vm._s(_vm.isOpen ? "▼" : "▶")+" ")])]),(_vm.schema[_vm.data.xtag])?_c('div',{class:[
            'tagButton',
            'd-flex',
            'align-items-center',
            'p-2',
            { highlight: _vm.data.nodeId === _vm.selectedTag },
          ],attrs:{"data-nodeid":_vm.data.nodeId}},[_c('div',[_vm._v(_vm._s(_vm.data.xtag))])]):_vm._e()]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOpen),expression:"isOpen"}]},[_c('div',{staticClass:"pl-4"},_vm._l((_vm.childNodes),function(child,index){return _c('recursive-tag',{key:index,ref:"recursiveTag",refInFor:true,attrs:{"peers":[],"data":child,"self":index,"parentList":_vm.childNodes,"selectedTag":_vm.selectedTag,"parentNodeId":_vm.data.nodeId,"parentData":_vm.data}})}),1),_c('div',{staticClass:"pl-4 d-flex"},[(_vm.schema[_vm.data.xtag])?_c('div',{class:[
              'tagButtonafter',
              'd-flex',
              'align-items-center',
              'p-2',
              'mb-1',
              { highlight: _vm.data.nodeId === _vm.selectedTag },
            ],attrs:{"data-nodeid":_vm.data.nodeId}},[_c('div',{staticClass:"custom-xtag"},[_vm._v(" "+_vm._s(_vm.data.xtag)+" ")])]):_vm._e(),(
              typeof _vm.parentData === `object` &&
              typeof _vm.parentData[_vm.parentData.xtag][_vm.parentList.length - 1] !==
                `string` &&
              _vm.schema[_vm.parentData.xtag].textContent
            )?_c('textarea',{ref:"inputtext_1",staticClass:"inputtext",class:[
              { bold: _vm.parentData.xtag === 'b' || _vm.parentData.ancestors.b },
              { italic: _vm.parentData.xtag === 'i' || _vm.parentData.ancestors.i },
              {
                underline: _vm.parentData.xtag === 'u' || _vm.parentData.ancestors.u,
              },
            ],attrs:{"rows":"1"},on:{"click":function($event){$event.stopPropagation();return _vm.selectTag(_vm.parentData.xtag, _vm.parentNodeId)},"input":_vm.append_text,"mouseup":() =>
                _vm.handleMouseUp(
                  _vm.$event,
                  _vm.name || _vm.parentData.xtag,
                  _vm.data.nodeId || _vm.parentNodeId
                ),"mousedown":_vm.handleMouseDown}}):_vm._e()])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }